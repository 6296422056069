/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import ErrorBoundary from 'components/ErrorBoundary';
import PageWrapper from 'components/PageWrapper';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDispatch } from 'react-redux';
import { all, call, Pattern, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions as globalActions } from 'store/globalSlice';
import {
  FETCH_FULFILLED_SUFFIX,
  FETCH_PENDING_SUFFIX,
  FETCH_REJECTED_SUFFIX,
  WITH_SUCCESS_CALLBACK,
} from 'utils/constants';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { getFetchStartReg } from 'utils/regExps';

type TFn = (payload?: any) => void;
type TArrayFn = (payload?: any) => Array<any> | undefined;

function getCurrentSaga(actions: any, sliceKey: string, extraSagas: TArrayFn) {
  // 对参数兼容处理
  if (!extraSagas) {
    // eslint-disable-next-line no-param-reassign
    extraSagas = () => [];
  }
  if (typeof extraSagas !== 'function') {
    console.error('extraSagas为函数');
    // eslint-disable-next-line no-param-reassign
    extraSagas = () => [];
  }
  // 初始化基础数据
  function* initData(action: IRedux.TAction) {
    try {
      yield all([
        // TODO: asyncThunk的返回与Action格式类型不兼容，不得不这样写；
        // 可继续添加更多初始数据的请求
      ]);

      // 数据初始化完毕后，dispatch Finish的Action，让一些等待基础数据的操作能够获得信号
      yield put({ type: `${action.type}Finish`, payload: {} });
    } catch (error) {
      yield put(globalActions.operateError(error));
    }
  }

  /**
   * loading的统一处理，如果需要更细粒度的控制，可以关闭此处理
   * @param action
   */
  function* fetchLoadingNoodles(action: IRedux.TAction) {
    // 开始loading
    let actionName = action.type.match(/\/(\S*)\/pending/)[1] || 'loadingAction';
    actionName = actionName.replace(WITH_SUCCESS_CALLBACK, '');
    yield put(
      actions.setLoading({
        actionKey: actionName,
        actionValue: true,
      }),
    );
    // yield put(actions.setLoading(true));
    // 等待结果
    yield take([
      action.type.replace(FETCH_PENDING_SUFFIX, FETCH_FULFILLED_SUFFIX),
      action.type.replace(FETCH_PENDING_SUFFIX, FETCH_REJECTED_SUFFIX),
    ]);
    // 结束loading
    // yield put(actions.setLoading(false));
    yield put(
      actions.setLoading({
        actionKey: actionName,
        actionValue: false,
      }),
    );
  }

  // TODO: 这里有问题，改下面的everyWatcher，现在没有用到
  function* watcher(type: Pattern<any>, process: (action: IRedux.TAction) => any) {
    yield takeLatest(type, process);
  }

  // TODO: 问题出在takeLatest上，多个请求并发会导致只更新最新的action，所以loading会有问题，改成takeEvery就不会有问题了
  // TODO: 不确定改成这个会不会影响其他地方，需要谨慎对待
  function* everyWatcher(type: Pattern<any>, process: (action: IRedux.TAction) => any) {
    yield takeEvery(type, process);
  }

  const rootSaga = function* pageRootSaga() {
    yield all([
      call(() => watcher(actions.initData, initData)),
      call(() =>
        everyWatcher(
          // 捕获了当前namespace全部的异步请求，如果有异步请求不需要触发loading，可直接配置例外
          (action: IRedux.TAction) => !isEmpty(action.type.match(getFetchStartReg(sliceKey))),
          fetchLoadingNoodles,
        ),
      ),
      ...extraSagas(),
    ]);
  };
  return rootSaga;
}

/**
 * 公共actions
 */

export const commonActions = {
  refresh: createAction('common/refresh', () => ({
    payload: {},
  })),
  setLoading: createAction('common/setLoading', (payload: any) => ({
    payload,
  })),
  initData: createAction('common/initData', () => ({
    payload: {},
  })),
  initDataFinish: createAction('common/initDataFinish', () => ({
    payload: {},
  })),
};

/**
 *
 * @param fn 函数组件
 * @param slice slice整体
 * @param extraSagas 可选参数
 * @returns React.Node
 */
export default function connect(fn: TFn, slice: any, extraSagas?: TArrayFn) {
  return function () {
    const extraReducers = createReducer({ refresh: 0, loading: false, loadingAction: {} }, builder => {
      builder.addCase(commonActions.refresh, state => {
        state.refresh += 1;
      });
      builder.addCase(commonActions.setLoading, (state, action) => {
        if (typeof action.payload === 'object') {
          state.loadingAction = {
            ...state.loadingAction,
            [action.payload.actionKey]: action.payload.actionValue,
          };
          return;
        }
        state.loading = action.payload;
      });
      builder.addCase(commonActions.initData, state => {
        state.loading = true;
      });
      builder.addCase(commonActions.initDataFinish, state => {
        state.loading = false;
      });
    });
    const { name } = slice;
    const commoneName = `${name}Common`;
    useInjectReducer({
      key: name,
      reducer: slice.reducer,
    });
    useInjectReducer({
      key: commoneName,
      reducer: extraReducers,
    });
    // eslint-disable-next-line max-len
    useInjectSaga({ key: name, saga: getCurrentSaga({ ...commonActions, ...slice.actions }, name, extraSagas) });
    const dispatch = useDispatch();
    React.useEffect(() => {
      dispatch(commonActions.initData());
    }, []);
    const children = fn.call(this);
    const Page = React.createElement(PageWrapper, null, children);
    return React.createElement(ErrorBoundary, null, Page);
  };
}
