/*
 * @Author: 董方旭
 * @Date: 2021-02-08 21:13:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-07 11:28:11
 * @Description: pass conf
 */
import { getCookie } from 'utils/utils';

const PLATFORM_COOKIE = 'tblh-platform';

const conf = {
  enable: true,
  uri: {
    // login: '/static/pass.html',
    // logout: '/static/pass.html#/logout',
    // editpwd: '/static/pass.html#/editpwd',
    // bindphone: '/static/pass.html#/bindphone',
    login: '/pass/login',
    logout: '/pass/logout',
    editpwd: '/pass/editpwd',
    bindphone: '/pass/bindphone',
  },
  platformMap: {
    online: 'TSC-TBLH-SCOMS|MTExMQ',
    uat: 'TSC-TBLH-SCOMS|MTExMw',
    test: 'TSC-TBLH-SCOMS|MTExNg',
  },
};

export const gotoPass = type => {
  if (conf.enable && conf.uri[type]) {
    const platform =
      getCookie(PLATFORM_COOKIE) || conf.platformMap[process.env.PROJECT_ENV];
    window.location.href = `${conf.uri[type]}?platform=${platform}`;
  }
};
