/*
 * @Author: 李淳
 * @Date: 2020-07-23 11:39:30
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-22 16:43:10
 * @Description: file content
 */
import { Layout } from 'antd';
import styled from 'styled-components';

export const LayoutWrapper = styled(Layout)`
  height: 100vh;
  overflow: hidden;
  .scroll-content {
    height: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-y: scroll;
  }
  .main-content-wrap {
    height: 100%;
  }
`;

export const HeaderWrapper = styled(Layout.Header)`
  line-height: 49px !important;
  display: flex;
  box-shadow: 4px 5px 12px 0px rgba(0, 0, 0, 0.04);
  z-index: 999;

  .header-left {
    width: 185px;
    height: 49px;
    display: flex;
    align-items: center;
    padding-right: 66px;

    .logo {
      width: 116px;
      height: 32px;
    }

    &.collapsed {
      width: 55px;
      padding-right: 10px;
      .logo {
        width: 30px;
        height: 30px;
      }
    }
  }
  .header-right {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .menu-taggle {
      width: 35px;
      font-size: 20px;
    }

    .menu-tabs {
      height: 49px;
      flex: 1;
      overflow: hidden;
    }

    .global-action {
      width: 170px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 30px;
        cursor: pointer;

        &:first-child {
          margin-left: 0px;
        }
      }

      .ant-radio-group {
        display: block;
      }
    }
  }
`;

export const LogoWrapper = styled.div`
  font-size: 36px;
  color: #eee;
  text-align: center;
  padding: 10px;
`;

export const MultipleTabsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .tabs {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: auto;
    height: 50px;

    .ant-tabs {
      overflow: hidden;
      .ant-tabs-nav {
        margin-bottom: 0;

        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #999999;
            text-shadow: none;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-weight: 600;
              // color: #333333;
              text-shadow: none;
              background: #fff;
              color: #2a80ff;
            }
          }
        }
      }
    }
  }
`;

export default {
  LayoutWrapper,
  HeaderWrapper,
  LogoWrapper,
  MultipleTabsWrapper,
};
