/*
 * @Author: 董方旭
 * @Date: 2021-03-04 11:43:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-23 14:34:10
 * @Description: longan埋点参数配置
 * 参考：import { ILonganConfig } from 'longan-sdk/dist/bundle';
 */

const config = {
  open: true, // 是否开启longan
  project_name: 'OMS', // 项目名称
  env: {
    uat: 'uat-scc-oms.sftcwl.com.cn',
    online: 'scc-oms.sf-express.com',
    develop: window.location.host,
  },
  loginUser: '',
  filter_fields: [], // 过滤掉不想上传的字段
  filter_confitions: [], // 过滤掉不想上传的功能
  additional_info: {}, // 自定义信息
  hash_route: false, // 开启代表有hash路由流量统计
  filter_error_msg: ['ResizeObserver'], // 过滤不想上报的错误。使用indexOf做匹配，请传errorMessage的关键字
};
export default config;
