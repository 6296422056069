/*
 * @Author: 董方旭
 * @Date: 2021-02-23 14:31:53
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-25 19:37:04
 * @Description: 菜单
 */
import { Menu } from 'antd';
import { addFlag2Route, concatPath, filterAuthedRoute, hasChildren } from 'components/CoreRouter';
import IconFont from 'components/IconFont';
import routeConfig from 'configs/dev.router.conf';
import { RouteConfig } from 'configs/router.conf';
import longan from 'longan-sdk';
import React, { memo, useEffect, useState } from 'react';
import { InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actions } from 'store/globalSlice';
import { selectActiveCacheKey, selectCachingKeys, selectLoginUserFunctionAuth } from 'store/selectors';
import styled from 'styled-components';
import { menuMessages } from 'utils/messages';

import CustomLink from './CustomLink';

const MenuWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  /* Firefox */
  scrollbar-width: none;
  /* IE10+ */
  -ms-overflow-style: none;
  /* Chrome Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon,
  .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 20px;
    vertical-align: 0;
  }
`;

const validMenuItem = ({ path, hidden }: RouteConfig) => path && !path.match(/:/) && !hidden;

const isStaticPath = ({ path, hidden }: RouteConfig) => !path.match(/:/) && !hidden;

const formatMenuItem = (intl: InjectedIntl, configs: RouteConfig[], parentPath = '') =>
  configs.filter(validMenuItem).map(({ path, children, exist, icon }) => {
    const menuIcon = typeof icon === 'string' ? <IconFont type={icon} /> : icon;
    const text = intl.formatMessage(menuMessages[path]);
    const allPath = concatPath(parentPath, path);
    if (hasChildren(children)) {
      const staticChildren = children.filter(isStaticPath);
      if (staticChildren.length) {
        return (
          <Menu.SubMenu
            key={path}
            title={
              exist ? (
                <CustomLink
                  to={allPath}
                  /** 切换路由前修改title ---start--- */
                  beforeClick={() => {
                    window.document.title = `OMS-${text}`;
                  }}
                  /** 切换路由前修改title ---start--- */
                >
                  {text}
                </CustomLink>
              ) : (
                text
              )
            }
            icon={menuIcon}
          >
            {formatMenuItem(intl, staticChildren, allPath)}
          </Menu.SubMenu>
        );
      }
    }

    return (
      <Menu.Item key={allPath} title={text} icon={menuIcon}>
        <CustomLink
          to={allPath}
          /** 切换路由前修改title ---start--- */
          beforeClick={() => {
            window.document.title = `OMS-${text}`;
          }}
          /** 切换路由前修改title ---start--- */
        >
          {text}
        </CustomLink>
      </Menu.Item>
    );
  });

/**
 * 左侧菜单组件
 */
const CustomMenu = memo((props: InjectedIntlProps) => {
  const { intl } = props;
  const dispatch = useDispatch();
  const authList = useSelector(selectLoginUserFunctionAuth);
  const cachingKeys = useSelector(selectCachingKeys);
  const activeCacheKey = useSelector(selectActiveCacheKey);
  const location = useLocation();

  // eslint-disable-next-line
  const checkAuth = (item: any) =>
    (authList || []).indexOf(item.authOnlyNoSecret) !== -1 || (authList || []).indexOf(item.authOnlySecret) !== -1;
  const authFlagRoutes = addFlag2Route(routeConfig, checkAuth);
  const routes = filterAuthedRoute(authFlagRoutes);
  const rootSubmenuKeys = routes.filter(item => !!item.children && !item.exist).map(filterItem => filterItem.path);

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    // 初始进入系统，需求修正title，否则会展示默认的title
    const paths = location.pathname.split('/');
    const titleKeyIndex = paths.findIndex(path => path.includes(':'));
    const titleKey = titleKeyIndex === -1 ? paths[paths.length - 1] : paths[titleKeyIndex - 1];
    window.document.title = `OMS-${intl.formatMessage(menuMessages[titleKey || 'homePage'])}`;
  }, []);

  useEffect(() => {
    const endIndex = activeCacheKey.indexOf('/:');
    setOpenKeys(activeCacheKey.split('/').slice(1));
    if (endIndex === -1) {
      // 不是详情页面
      setSelectedKeys([activeCacheKey]);
    } else {
      // 详情页面
      const key = activeCacheKey.slice(0, endIndex);
      const selectedMenuIndex = key.lastIndexOf('/');
      const selectedMenu = key.slice(0, selectedMenuIndex);
      setSelectedKeys([selectedMenu]);
    }
    // 触发resize事件
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }, [activeCacheKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // eslint-disable-next-line
  const onClick = (menuItem: any) => {
    const { key } = menuItem;
    const endIndex = key.indexOf('/:');
    setOpenKeys(key.split('/').slice(1));
    setSelectedKeys([endIndex === -1 ? key : key.slice(0, endIndex)]);
    const mesKey = key.split('/').pop();
    const text = intl.formatMessage(menuMessages[mesKey]);
    longan.dispatch({
      event_desc: text,
      event_name: `page-${text}`,
      event_type: 'selfDefine',
    });
    if (!cachingKeys.includes(key)) {
      dispatch(actions.updateCachingKeys([...cachingKeys, key]));
    }
    if (activeCacheKey !== key) {
      dispatch(actions.updateActiveCacheKey(key));
    }
  };

  return (
    <MenuWrapper>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={onClick}
      >
        {formatMenuItem(intl, routes)}
      </Menu>
    </MenuWrapper>
  );
});

export default injectIntl(CustomMenu);
