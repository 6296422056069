/*
 * @Author: 董方旭
 * @Date: 2021-01-10 21:25:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-23 15:33:27
 * @Description: The global state selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './globalSlice';
import { IStore, IUserInfo } from './types';

const selectGlobal = (state: IRedux.IStore) => state.global || initialState;

const selectRouter = (state: IRedux.IStore) => state.router;

const selectLanguage = (state: IRedux.IStore) => state.language;

const selectCurrentLanguage = createSelector(selectLanguage, languageState => languageState.locale);

const selectError = createSelector(selectGlobal, globalState => globalState.error);

const selectLoginUserInfo = createSelector(
  selectGlobal,
  (globalState: IStore): IUserInfo => globalState.userData.userInfo,
);

// 登录用户具有的功能权限码集合
const selectLoginUserFunctionAuth = createSelector(selectGlobal, globalState => globalState.userData.functionAuth);

const selectLayout = createSelector(selectGlobal, (globalState: IStore) => globalState.layout);

const selectLocation = createSelector(selectRouter, routerState => routerState.location);

const selectLoading = createSelector(selectGlobal, (globalState: IStore) => globalState.loading);

const makeSelectLoading = (key: string) =>
  createSelector(selectGlobal, (globalState: IStore) => globalState.loadingMap[key]);

const selectCrumbsMap = createSelector(selectGlobal, (globalState: IStore) => globalState.crumbsMap);

const selectDesensitizeConf = createSelector(selectGlobal, globalState => globalState.desensitizeConf);

const selectDesensitizeFlagMap = createSelector(selectGlobal, globalState => globalState.desensitizePageFlagMap);

const selectConfigMap = createSelector(selectGlobal, (globalState: IStore) => globalState.configMap);

const selectConfigMapByKey = (key: string) =>
  createSelector(selectGlobal, (globalState: IStore) => {
    const map = globalState.configMap || {};
    return map[key] || [];
  });

const selectCachingKeys = createSelector(selectGlobal, (globalState: IStore) => globalState.cachingKeys);

const selectActiveCacheKey = createSelector(selectGlobal, (globalState: IStore) => globalState.activeCacheKey);

const selectAllWarehouseList = createSelector(selectGlobal, (globalState: IStore) => globalState.allWarehouseList);

const selectWarehouseList = createSelector(selectGlobal, (globalState: IStore) => globalState.warehouseList);

const selectAllCompanyList = createSelector(selectGlobal, (globalState: IStore) => globalState.allCompanyList);

const selectCompanyList = createSelector(selectGlobal, (globalState: IStore) => globalState.companyList);

const selectAllVendorList = createSelector(selectGlobal, (globalState: IStore) => globalState.allVendorList);

const selectVendorList = createSelector(selectGlobal, (globalState: IStore) => globalState.vendorList);

const selectCompanyGroupList = createSelector(selectGlobal, (globalState: IStore) => globalState.companyGroupList);

export {
  selectGlobal,
  selectCurrentLanguage,
  selectLoading,
  makeSelectLoading,
  selectError,
  selectLocation,
  selectLayout,
  selectLoginUserFunctionAuth,
  selectLoginUserInfo,
  selectCrumbsMap,
  selectDesensitizeFlagMap,
  selectConfigMap,
  selectCachingKeys,
  selectActiveCacheKey,
  selectAllWarehouseList,
  selectWarehouseList,
  selectAllCompanyList,
  selectCompanyList,
  selectDesensitizeConf,
  selectCompanyGroupList,
  selectVendorList,
  selectAllVendorList,
  selectConfigMapByKey,
};
