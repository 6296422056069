/*
 * @Author: 李淳
 * @Date: 2020-06-22 19:32:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-27 11:25:38
 * @Description: 全局公共状态
 */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import longan, { longanUpdateConfig } from 'longan-sdk';
import { createServiceAsyncThunk } from 'utils/@reduxjs/toolkit';

import services from './services';
import { IDesensitizeConf, IStore } from './types';
import { generateDesensitizeConf } from './utils';

export const NAMESPACE = 'GLOBAL';

const path = window.location.pathname + window.location.search;

const isOnFrame = window.frames.length !== window.parent.frames.length;

export const initialState: IStore = {
  init: false, // 数据初始化状态
  loading: false, // 总loading状态
  loadingMap: {}, // 更细致的loading状态
  error: null,
  userData: {
    userInfo: {},
    functionAuth: [],
  },
  layout: {
    collapsed: false,
  },
  crumbsMap: {},
  // 页面脱敏状态: { 'pageA': '0', 'pageB': '1',} 0 是脱敏、1 是不脱敏，只有可以进行脱敏切换的页面才有这个配置
  // 每个页面右上角的小眼睛状态来自于这里，切换也改变的是这里
  desensitizePageFlagMap: {},
  // 用户脱敏配置，这里是根据权限和路由生成的，和页面并不是一一对应的（详情页可以存在多个，但是这里的详情页只有一个）
  desensitizeConf: {},
  configMap: {},
  // 当前缓存的tab页面key
  cachingKeys: [path === '/' ? '/homePage' : path],
  activeCacheKey: path === '/' ? '/homePage' : path,
  warehouseList: [],
  allWarehouseList: [],
  companyList: [],
  allCompanyList: [],
  companyGroupList: [],
  allVendorList: [],
  vendorList: [],
};

// TODO: loading的处理
export const getLoginUserInfo = createServiceAsyncThunk(`${NAMESPACE}/getLoginUserInfo`, async () =>
  services.getLoginUserInfoService(),
);

export const getIssueConf = createServiceAsyncThunk(`${NAMESPACE}/getIssueConf`, async () =>
  services.getIssueConfService(),
);

export const getAllWarehouseList = createServiceAsyncThunk(`${NAMESPACE}/getAllWarehouseList`, async () =>
  services.getAllWarehouseListService(),
);

export const getWarehouseList = createServiceAsyncThunk(`${NAMESPACE}/getWarehouseList`, async () =>
  services.getWarehouseListService(),
);

export const getAllCompanyList = createServiceAsyncThunk(`${NAMESPACE}/getAllCompanyList`, async () =>
  services.getAllCompanyListService(),
);

export const getCompanyList = createServiceAsyncThunk(`${NAMESPACE}/getCompanyList`, async () =>
  services.getCompanyListService(),
);

export const getAllVendorList = createServiceAsyncThunk(`${NAMESPACE}/getAllVendorList`, async () =>
  services.getAllVendorListService(),
);

export const getVendorList = createServiceAsyncThunk(`${NAMESPACE}/getVendorList`, async () =>
  services.getVendorListService(),
);

export const getCompanyGroupList = createServiceAsyncThunk(`${NAMESPACE}/getCompanyGroupList`, async () =>
  services.getCompanyGroupListService(),
);

export const getVersion = createServiceAsyncThunk(`${NAMESPACE}/getVersion`, async () => services.getVersionService());

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    operateError(state, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateLayoutCollapsed(state, action: PayloadAction<boolean>) {
      state.layout.collapsed = action.payload;
    },
    initGlobalData(state) {
      state.loading = true;
      // state.desensitizeFlagMap = initDesensitizeFlag();
      state.cachingKeys = [path === '/' ? '/homePage' : path];
      state.activeCacheKey = path === '/' ? '/homePage' : path;
      // to saga
    },
    initGlobalDataFinish(state) {
      state.loading = false;
      state.init = true;
    },
    addCrumbNameMap(state, { payload }: PayloadAction<Record<string, string>>) {
      state.crumbsMap = { ...state.crumbsMap, ...payload };
    },
    updateDesensitizePageFlagMap(state, { payload }: PayloadAction<{ [key: string]: '0' | '1' }>) {
      state.desensitizePageFlagMap = { ...state.desensitizePageFlagMap, ...payload };
    },
    updateDesensitizeConf(state, { payload }: PayloadAction<{ [key: string]: IDesensitizeConf }>) {
      state.desensitizeConf = { ...state.desensitizeConf, ...payload };
    },
    updateCachingKeys(state, action: PayloadAction<string[]>) {
      state.cachingKeys = action.payload;
    },
    updateActiveCacheKey(state, action: PayloadAction<string>) {
      state.activeCacheKey = action.payload;
    },
  },
  // 异步的成功、失败处理，以及对于非此slice的action的处理
  extraReducers: {
    [getLoginUserInfo.fulfilled.type]: (state, action) => {
      const data = action.payload?.data;
      const { privilege_ids, user_info = {} } = data || {};
      const authList = Array.isArray(privilege_ids) ? privilege_ids : [];
      state.userData = {
        functionAuth: authList,
        userInfo: user_info || {},
      };
      longanUpdateConfig({
        loginUser: user_info?.sfucode,
        additional_info: {
          lid: action.payload.lid,
          cookie: document.cookie,
        },
      });
      // 用户登录接口成功返回之后，生成项目整体的脱敏配置对象
      state.desensitizeConf = generateDesensitizeConf(authList);
      longan.dispatch({
        event_name: 'SC-OMS-登录',
        event_desc: '登录',
        event_type: 'selfDefine',
      });
    },
    [getIssueConf.fulfilled.type]: (state, action) => {
      state.configMap = action.payload?.data;
    },
    [getAllWarehouseList.fulfilled.type]: (state, action) => {
      state.allWarehouseList = Array.isArray(action.payload?.data?.list) ? action.payload.data.list : [];
    },
    [getWarehouseList.fulfilled.type]: (state, action) => {
      state.warehouseList = Array.isArray(action.payload?.data?.list) ? action.payload.data.list : [];
    },
    [getAllCompanyList.fulfilled.type]: (state, action) => {
      state.allCompanyList = Array.isArray(action.payload?.data?.list) ? action.payload.data.list : [];
    },
    [getCompanyList.fulfilled.type]: (state, action) => {
      state.companyList = Array.isArray(action.payload?.data?.list) ? action.payload.data.list : [];
    },
    [getAllVendorList.fulfilled.type]: (state, action) => {
      state.allVendorList = Array.isArray(action.payload?.data) ? action.payload.data : [];
    },
    [getVendorList.fulfilled.type]: (state, action) => {
      state.vendorList = Array.isArray(action.payload?.data) ? action.payload.data : [];
    },
    [getCompanyGroupList.fulfilled.type]: (state, action) => {
      state.companyGroupList = Array.isArray(action.payload?.data?.list) ? action.payload.data.list : [];
    },
    [getVersion.fulfilled.type]: (state, action) => {
      const { id = -1, publishVersion = 'V0.0.1', noticeContent = '默认提示信息' } = action.payload?.data || {};
      const { sfucode = 0 } = state.userData?.userInfo ?? {};
      if (!!sfucode && +id > +window.localStorage.getItem(`version_id_${sfucode}`) && !isOnFrame) {
        window.localStorage.setItem(`version_id_${sfucode}`, id);
        Modal.info({
          title: `发现新版本 ${publishVersion}`,
          content: noticeContent,
          okText: '确认',
          closable: true,
          zIndex: 100000,
          style: { whiteSpace: 'pre-line', fontSize: '20px' },
        });
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = slice;
