import { Dropdown, Menu as AntdMenu, Tooltip } from 'antd';
import ToggleLanguage from 'components/ToggleLanguage';
import authMap from 'configs/auth.conf';
import { i18nConf } from 'configs/base.conf';
import { gotoPass } from 'configs/pass.conf';
import Auth from 'containers/AuthController';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import usePageJump from 'hooks/usePageJump';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import faqPng from 'static/images/ic_faq_center@2x.png';
import announcePng from 'static/images/ic_info_announce@2x.png';
import hidePng from 'static/images/ic_info_hide.png';
import seePng from 'static/images/ic_info_see@2x.png';
import fengxiangbiaoPng from 'static/images/img_fengxiangbiao_logo.png';
import personagePng from 'static/images/nav_personage@3x.png';
import logoPng from 'static/images/oms_logo.svg';
import logoSmallPng from 'static/images/pic_logo_small@2x.png';
import { actions } from 'store/globalSlice';
import {
  selectCurrentLanguage,
  selectDesensitizeConf,
  selectDesensitizeFlagMap,
  selectLoginUserInfo,
} from 'store/selectors';
import messages, { headerBarTips } from 'utils/messages';
import { setCookie } from 'utils/utils';

import MultipleTabs from '../components/MultipleTabs';
import { HeaderWrapper } from '../components/Wrappers';

const { useEffect, useState } = React;
interface IProps extends InjectedIntlProps {
  children: React.ReactNode;
  collapsed: boolean;
}

const projectEnv = process.env.PROJECT_ENV;

function Header(props: IProps) {
  const { intl } = props;
  const pageJump = usePageJump(intl);
  const userInfo = useSelector(selectLoginUserInfo);
  const local = useSelector(selectCurrentLanguage);
  const desensitizeConf = useSelector(selectDesensitizeConf);
  const desensitizeFlagMap = useSelector(selectDesensitizeFlagMap);
  // 页面是否展示脱敏切换按钮
  const [isShowDesensitizeSwitch, setIsShowDesensitizeSwitch] = useState(false);
  const [desensitizeFlag, setDesensitizeFlag] = useState('0');
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isOnFrame = window.frames.length !== window.parent.frames.length;

  // useEffect(() => {
  //   const theme = JSON.parse(localStorage.getItem('theme') || '{}');
  //   (window as any).less
  //     .modifyVars(theme);
  // }, []);

  /**
   * 判断当前页面是否展示脱敏切换按钮
   * @description 若isForcedDesensitized为true，则不会显示脱敏切换；否则需要看isEdit是否为true
   * @param pathUrl 页面路径
   * @returns boolean
   */
  const checkIsShowDesensitizeSwitch = (pathUrl: string) => {
    if (Object.keys(desensitizeFlagMap).includes(pathUrl)) {
      return true;
    }
    return false;
  };

  // 路由、desensitizeConf变化时增加对应的页面脱敏状态
  useEffect(() => {
    const pathUrl = pathname.split('?')[0];
    const path = pathname.split('/:')[0];
    if (
      !Object.keys(desensitizeFlagMap).includes(pathUrl) &&
      desensitizeConf?.[path]?.isEdit &&
      !desensitizeConf?.[path]?.isForcedDesensitized
    ) {
      dispatch(actions.updateDesensitizePageFlagMap({ [pathUrl]: '0' }));
    }
    setIsShowDesensitizeSwitch(checkIsShowDesensitizeSwitch(pathUrl));
    setDesensitizeFlag(desensitizeFlagMap[pathUrl]);
  }, [pathname, desensitizeConf, desensitizeFlagMap]);

  useEffect(() => {
    const pathUrl = pathname.split('?')[0];
    setDesensitizeFlag(desensitizeFlagMap[pathUrl]);
  }, [JSON.stringify(desensitizeFlagMap)]);

  const handleLanguageChange = (lang: string) => {
    dispatch({
      type: CHANGE_LOCALE,
      payload: lang,
    });
    setCookie(i18nConf.cookieName, lang);
  };

  // 页面脱敏切换
  const handlePageDesensitizeConfSwitch = () => {
    const path = pathname.split('?')[0];
    const currentDesensitize = desensitizeFlag === '1' ? '0' : '1';
    dispatch(
      actions.updateDesensitizePageFlagMap({
        [path]: currentDesensitize,
      }),
    );
  };

  const goToAnnounce = () => {
    const newPath = '/publishManage/announceManage';
    pageJump(newPath);
  };

  const goToHelpCenter = () => {
    const newPath = '/helpCenter';
    pageJump(newPath);
  };

  const gotoFengxiangbiao = () => {
    const path =
      projectEnv === 'online'
        ? 'http://scc-wpm.sf-express.com/#/fengjingtai/BIM2?fromuser=127'
        : 'http://scc-wpm.sf-express.com/#/fengjingtai/BIM2?fromuser=127';
    window.open(path, '_blank');
  };

  const handleLogout = () => {
    gotoPass('logout');
  };

  return (
    <HeaderWrapper>
      <div className={`header-left ${props.collapsed ? 'collapsed' : ''}`}>
        {isOnFrame ? null : <img className="logo" src={props.collapsed ? logoSmallPng : logoPng} alt="logo" />}
      </div>
      <div className="header-right">
        <div className="menu-taggle">{props.children}</div>
        <div className="menu-tabs">
          <MultipleTabs />
        </div>
        {/* 右侧操作区域 */}
        <div className="global-action" style={{ width: 'auto' }}>
          <Auth authCode={authMap.AUTH_PAGE_FENGXIANGBIAO}>
            <Tooltip title="丰向标">
              <img src={fengxiangbiaoPng} alt="丰向标" onClick={gotoFengxiangbiao} />
            </Tooltip>
          </Auth>
          <Tooltip title={intl.formatMessage(headerBarTips.help_center)}>
            <img src={faqPng} alt="faq" onClick={goToHelpCenter} style={{ marginRight: 30 }} />
          </Tooltip>
          {i18nConf.enable && <ToggleLanguage value={local} onChange={handleLanguageChange} />}
          <Auth authCode={authMap.AUTH_PAGE_AnnounceManage}>
            <Tooltip title={intl.formatMessage(headerBarTips.view_announcements)}>
              <img src={announcePng} alt="announce" onClick={goToAnnounce} />
            </Tooltip>
          </Auth>
          {isShowDesensitizeSwitch && (
            <Tooltip placement="bottomRight" title={intl.formatMessage(headerBarTips.desensitize_switch)}>
              <img
                src={desensitizeFlag === '1' ? seePng : hidePng}
                alt="desensitize"
                onClick={handlePageDesensitizeConfSwitch}
              />
            </Tooltip>
          )}
          {isOnFrame ? null : (
            <Dropdown
              trigger={['click']}
              overlay={
                <AntdMenu>
                  <AntdMenu.Item key="code">
                    <span>{userInfo?.sfucode || '-'}</span>
                  </AntdMenu.Item>
                  <AntdMenu.Divider />
                  <AntdMenu.Item key="logout" onClick={handleLogout}>
                    <span>{intl.formatMessage(messages.logout)}</span>
                  </AntdMenu.Item>
                </AntdMenu>
              }
            >
              <img src={personagePng} alt="avatar" />
            </Dropdown>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
}

export default injectIntl(Header);
