/*
 * @Author: 董方旭
 * @Date: 2021-02-26 15:57:16
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-02-26 16:03:27
 * @Description: 列表页 Wrapper组件
 */
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export default PageWrapper;
