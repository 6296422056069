/*
 * @Author: 李淳
 * @Date: 2020-06-15 14:18:50
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-08-31 18:11:35
 * @Description: file content
 */
// prefix
export const POST_ACTION_PREFIX = '/post';
export const GET_ACTION_PREFIX = '/get';

// suffix
export const FETCH_PENDING_SUFFIX = '/pending';
export const FETCH_FULFILLED_SUFFIX = '/fulfilled';
export const FETCH_REJECTED_SUFFIX = '/rejected';

export const WITH_SUCCESS_CALLBACK = 'withSuccssCallback';
// reg
export const GET_START_REG = new RegExp(`${GET_ACTION_PREFIX}.*(${FETCH_PENDING_SUFFIX})$`);

// errno
export const USER_NOT_LOGIN_ERRNO = 110003; // 用户未登陆
export const USER_NOT_EXIST_ERRNO = 110018; // 用户不存在
export const USER_NOT_CHOOSE_CUSTOMER_ERRNO = 130002; // 用户未选择customer
export const USER_CHOOSE_WRONG_CUSTOMER_ERRNO = 110018; // 用户选择customer越权

// operate constant
export const CREATE = 'create';
export const EDIT = 'edit';
export const VIEW = 'view';
export const APPROVE = 'approve';
export const DELETE = 'delete';
export const CHECK = 'check';
export const DRAFT = 'draft';
export const IMPORT = 'import';

// scope
export const GLOBAL_MSG_SCOPE = 'cosmos.src.global';

// 同步es 需要延迟数据查询请求 延迟时间统一设定 1000ms
export const ES_DELAY_TIME = 1000;

// 用于存请求方式和请求接口的url
export const REQUEST_MATERIAL = { requestUrl: '', requestMethod: '' };

/** @constant 30天时间间隔（毫秒级） */
export const THIRTY_DAYS_MILLSECOND = 30 * 24 * 60 * 60 * 1000;
/** @constant 30天时间间隔（秒级） */
export const THIRTY_DAYS_SECOND = 30 * 24 * 60 * 60;
/** @constant 31天时间间隔（毫秒级） */
export const THIRTYONE_DAYS_MILLSECOND = 31 * 24 * 60 * 60 * 1000;
/** @constant 31天时间间隔（秒级） */
export const THIRTYONE_DAYS_SECOND = 31 * 24 * 60 * 60;

// datahub 地址
export const DATAHUB_UAT = 'http://uat-scc-datahub.sftcwl.com.cn/';
export const DATAHUB_ONLINE = 'https://scc-datahub.sf-express.com/';
export const DATAHUB_TEST = 'http://10.189.72.103:8001/';

export const DEFAULT_DATETIME_FORMAT_STRING = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_DATE_FORMAT_STRING = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT_STRING = 'HH:mm:ss';
export const DEFAULT_TIME_ZONE = 'Asia/Shanghai';

// 货主业务归属
export const COMPANY_BUSINESS_BELONG = {
  coldTransport: '4' as const, // 冷运
  medicine: '5' as const, // 医药
};
