import { useSelector } from 'react-redux';
import { selectLoginUserFunctionAuth } from 'store/selectors';

import checkAuth from './checkAuth';

interface IProps {
  authCode: Auth.TCode;
  children: any;
}

function Auth(props: IProps) {
  const fuctionAuth: Auth.TCode[] = useSelector(selectLoginUserFunctionAuth);

  return checkAuth(props.authCode, fuctionAuth)(props.children);
}

export function useAuth(authCode: number) {
  const fuctionAuth: Auth.TCode[] = useSelector(selectLoginUserFunctionAuth);
  return checkAuth(authCode, fuctionAuth)('_') === '_';
}

export default Auth;
