/*
 * @Author: 李淳
 * @Date: 2020-06-24 17:40:13
 * @LastEditors: 李淳
 * @LastEditTime: 2020-07-15 15:12:47
 * @Description: file content
 */
import { FETCH_FULFILLED_SUFFIX, FETCH_PENDING_SUFFIX, FETCH_REJECTED_SUFFIX } from 'utils/constants';

export const getPostStartReg = (namespace: string): RegExp =>
  new RegExp(`${namespace}/post.*(${FETCH_PENDING_SUFFIX})$`);

// 获取某个slice下面的所有Fetch Action，多用于统一处理loading等情况；
export const getFetchStartReg = (namespace: string): RegExp => new RegExp(`${namespace}/.*(${FETCH_PENDING_SUFFIX})$`);

export const getFetchEndReg = (namespace: string): RegExp =>
  new RegExp(`${namespace}/.*((${FETCH_REJECTED_SUFFIX})|(${FETCH_FULFILLED_SUFFIX}))$`);

// 获取某个slice下面的所有常规Fetch Action(get/post开头的)，以支持其他不需要loading的自定义Fetch Action
export const getRegularFetchStartReg = (namespace: string): RegExp =>
  new RegExp(`${namespace}/(get|post).*(${FETCH_PENDING_SUFFIX})$`);
