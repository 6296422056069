/*
 * @Author: 董方旭
 * @Date: 2021-02-23 14:31:53
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-10-15 11:19:10
 * @Description: 线下环境权限 - 主要有PAGE和FUNC两种权限, 在authmap中取到undefined，则默认显示权限
 */

// 请按照权限码顺序排列
const authMap = {
  AUTH_PAGE_sample: 999, // 开发占用
  AUTH_FUNC_sample: 999,

  // 货主管理
  AUTH_PAGE_companyManage_NOSECRET: 1145,
  AUTH_PAGE_companyManage_SECRET: 1144,
  AUTH_FUNC_companyManage_DETAIL: 1092,
  AUTH_FUNC_companyManage_CREATE: 1093,
  AUTH_FUNC_companyManage_MODIFY: 1094,
  AUTH_FUNC_companyManage_LOG: 1095,
  AUTH_FUNC_companyManage_EFFECTIVE: 1096,

  // 系统参数配置
  AUTH_PAGE_SysParamsConfig_NOSECRET: 1196,
  AUTH_FUNC_SysParamsConfig_EDIT: 1190,

  // 节点规则
  AUTH_PAGE_nodeRule_NOSECRET: 1147,
  AUTH_FUNC_nodeRule_DETAIL: 1104,
  AUTH_FUNC_nodeRule_CREATE: 1106,
  AUTH_FUNC_nodeRule_MODIFY: 1105,

  // 库存交易流水
  AUTH_PAGE_transactionFlow_NOSECRET: 1153,

  // 货主组
  AUTH_PAGE_companyGroup_NOSECRET: 1143,
  AUTH_FUNC_companyGroup_VIEW: 1035,
  AUTH_FUNC_companyGroup_ADD: 1036,
  AUTH_FUNC_companyGroup_DELETE: 1037,

  // 配送管理
  AUTH_PAGE_TransOrderManage_SECRET: 1188, //？？？？
  AUTH_PAGE_TransOrderManage_NOSECRET: 1140,
  AUTH_FUNC_TransOrderManage_VIEW: 1023,
  AUTH_FUNC_TransOrderManage_DETAIL: 1024,
  AUTH_FUNC_TransOrderManage_VIEW_ROUTE: 1025,
  AUTH_PAGE_TransOrderManage_SEND: 1026,
  AUTH_PAGE_TransOrderManage_CANCEL: 1027,
  AUTH_PAGE_TransOrderManage_EDIT: 1028,
  AUTH_PAGE_TransOrderManage_EXPORT: 2351,

  // 调拨单管理
  AUTH_PAGE_DISTRIBUTE_MANAGE: 999,

  // 权限管理
  AUTH_PAGE_FUNCTIONMANAGE: 1156,
  AUTH_PAGE_ROLEMANAGE: 1157,

  // 出库单查询
  AUTH_PAGE_OutOrderSearch_SECRET: 1130,
  AUTH_PAGE_OutOrderSearch_NOSECRET: 1133,
  AUTH_FUNC_OutOrderSearch_EXPORT: 1009,
  AUTH_FUNC_OutOrderSearch_EXPORT_only_unsense: 999,
  AUTH_FUNC_OutOrderSearch_DETAIL: 1062,
  AUTH_FUNC_OutOrderSearch_CANCEL: 1074,
  AUTH_FUNC_OutOrderSearch_ROUTE: 1076,
  AUTH_FUNC_OutOrderSearch_PULLWMSORDER: 1123,
  AUTH_FUNC_OutOrderSearch_INVALID: 30592,
  AUTH_FUNC_OutOrderSearch_MEDICINE_FLAG: 999,

  // 出库温湿度管理
  AUTH_PAGE_OutboundTemperatureAndHumidity_NOSECRET: 29753,
  AUTH_FUNC_OutboundTemperatureAndHumidity_DELETE: 29754,
  AUTH_FUNC_OutboundTemperatureAndHumidity_IMPORT: 999,

  // 仓库管理
  AUTH_PAGE_WarehouseManage_SECRET: 1141,
  AUTH_PAGE_WarehouseManage_NOSECRET: 1142,
  AUTH_FUNC_WarehouseManage_CREATE: 1033,
  AUTH_FUNC_WarehouseManage_EDIT: 1034,
  AUTH_FUNC_WarehouseManage_NODE_VIEW: 1120,
  AUTH_FUNC_WarehouseManage_NODE_CREATE: 1121,
  AUTH_FUNC_WarehouseManage_NODE_EDIT: 1122,
  AUTH_FUNC_WarehouseManage_MAINTAIN_OPERATION: 1124,

  // 任务中心
  AUTH_PAGE_TaskCenter_NOSECRET: 1047,
  AUTH_FUNC_TaskCenter_COMPANY_CODE: 1048,

  // 规则中心-时效规则
  AUTH_PAGE_StrategyTimeRules_NOSECRET: 1150,
  AUTH_FUNC_StrategyTimeRules_VIEW: 1112,
  AUTH_FUNC_StrategyTimeRules_EDIT: 1113,
  AUTH_FUNC_StrategyTimeRules_CREATE: 1114,

  // 规则中心-极效规则

  AUTH_MENU_EffectiveRules_NOSECRET: 2158,
  AUTH_PUN_EffectiveRules_VIEW: 2158,
  AUTH_PUN_EffectiveRules_EDIT: 2159,
  AUTH_PUN_EffectiveRules_CREATE: 2160,

  // 模拟路由操作码规则
  AUTH_PAGE_RoutingOperationCodeRule_NOSECRET: 1149,
  AUTH_FUNC_RoutingOperationCodeRule_CREATE: 1111,
  AUTH_FUNC_RoutingOperationCodeRule_EDIT: 1110,
  AUTH_FUNC_RoutingOperationCodeRule_VIEW: 1109,

  // 入库管理
  AUTH_MENU_inboundManage: 1001,
  // 入库管理
  AUTH_PAGE_inboundManage_SECRET: 1136,
  AUTH_PAGE_inboundManage_NOSECRET: 1137,
  AUTH_FUNC_inboundManage_EXPORT: 1068,
  AUTH_FUNC_inboundManage_CANCEL: 1069,
  AUTH_FUNC_inboundManage_ISSUE: 1070,
  AUTH_FUNC_inboundManage_DETAIL: 1073,
  AUTH_FUNC_inboundManage_EDIT: 1126,
  AUTH_FUNC_inboundManage_REORDER: 1127,
  AUTH_FUNC_inboundManage_IMPORT: 999,
  AUTH_FUNC_inboundManage_INVALID: 999,
  AUTH_FUNC_inboundManage_MEDICINE_FLAG: 999,
  // 入库明细查询
  AUTH_PAGE_inboundDetailManage_NOSECRET: 1138,
  AUTH_FUNC_inboundDetailManage_EXPORT: 1071,
  // 入库序列号查询
  AUTH_PAGE_inboundNoManage_NOSECRET: 1139,
  AUTH_FUNC_inboundNoManage_EXPORT: 1072,

  // 分仓规则
  AUTH_PAGE_SubWarehouseRule_NOSECRET: 1148,
  AUTH_FUNC_SubWarehouseRule_VIEW: 1107,
  AUTH_FUNC_SubWarehouseRule_EDIT: 1108,

  // 管控规则
  AUTH_PAGE_AdminRuleManage_NOSECRET: 1149,
  AUTH_FUNC_AdminRuleManage_VIEW: 1109,
  AUTH_FUNC_AdminRuleManage_EDIT: 1110,
  AUTH_FUNC_AdminRuleManage_CREATE: 1111,
  AUTH_FUNC_AdminRuleManage_IMPORT: 999,

  // 极效规则
  AUTH_PAGE_effectiveRules: 1056,

  // 出库单处理
  AUTH_PAGE_OrderManage_SECRET: 1131,
  AUTH_PAGE_OrderManage_NOSECRET: 1134,
  AUTH_FUNC_OrderManage_VIEW: 1063,
  AUTH_FUNC_OrderManage_EDIT: 1082,
  AUTH_FUNC_OrderManage_INTERCEPT: 1080,
  AUTH_FUNC_OrderManage_CANCEL: 1087,
  AUTH_FUNC_OrderManage_LOG: 1078,
  AUTH_FUNC_OrderManage_SPLITOCCUPY: 999,
  AUTH_FUNC_OrderManage_SPLITOCCUPY_FORCE: 999,
  AUTH_FUNC_OrderManage_RELEASEALL: 1081,
  AUTH_FUNC_OrderManage_TMS: 1083,
  AUTH_FUNC_OrderManage_WMS: 1084,
  AUTH_FUNC_OrderManage_CANCELTMS: 1086,
  AUTH_FUNC_OrderManage_CARRIER: 1085,
  AUTH_FUNC_OrderManage_EXPORT: 1010,
  AUTH_FUNC_OrderManage_EXPORT_only_unsense: 999,
  AUTH_FUNC_OrderManage_IGNORE: 1125,
  AUTH_FUNC_OrderManage_MERGEORDER: 2239,
  AUTH_FUNC_OrderManage_DECRYPT: 9695,
  AUTH_FUNC_OrderManage_CANCELSTACK: 999,
  AUTH_FUNC_OrderManage_IMPORT: 999,
  AUTH_FUNC_OrderManage_PREPAY: 23303,
  AUTH_FUNC_OrderManage_medicineCheckGood: 29474,
  AUTH_FUNC_OrderManage_INVALID: 30593,
  AUTH_FUNC_OrderManage_DSCCARRIEREDIT: 999,
  AUTH_FUNC_OrderManage_MEDICINEUNUSUALRECHECK: 29474,

  // 商品管理模块
  AUTH_PAGE_GoodsManagement_NOSECRET: 1146,
  AUTH_FUNC_GoodsManagement_VIEW: 1097,
  AUTH_FUNC_GoodsManagement_CREATE: 1098,
  AUTH_FUNC_GoodsManagement_EDIT: 1099,
  AUTH_FUNC_GoodsManagement_PUSHORDER: 1100,
  AUTH_FUNC_GoodsManagement_EXPORT: 1115,

  //basicCodeConfig
  AUTH_PAGE_BasicCodeConfig_NOSECRET: 1154,
  AUTH_FUNC_BasicCodeConfig_EDIT: 1119,

  // 货主仓库库存管理
  AUTH_PAGE_CompanyWarehouseStockManage_NOSECRET: 1151,
  AUTH_FUNC_CompanyWarehouseStockManage_IMPORT: 2470,
  // 货主仓库管理
  AUTH_PAGE_CompanyStockManage_NOSECRET: 1152,

  // BOM管理
  AUTH_PAGE_BomManage_NOSECRET: 2245,
  AUTH_FUNC_BomManage_CREATE: 2244,
  AUTH_FUNC_BomManage_PUSH: 2424,

  // 供应商管理
  AUTH_PAGE_SupplierManage_NOSECRET: 2379,
  AUTH_PAGE_SupplierManage: 2372,
  AUTH_FUNC_SupplierManage_IMPORT: 2373,
  AUTH_FUNC_SupplierManage_PUSH: 2374,
  AUTH_FUNC_SupplierManage_EXPORT: 2375,
  AUTH_FUNC_SupplierManage_DETAIL: 2376,
  AUTH_FUNC_SupplierManage_LOG: 2377,
  AUTH_FUNC_SupplierManage_EDIT: 2378,

  // 库存中心内部对账差异
  AUTH_PAGE_StockInnerDiffBill: 1202,

  // 库存快照
  AUTH_PAGE_StockPicture: 1199,

  // 缺货信息管理 ？？？
  AUTH_PAGE_LackSku_SECRET: 1132,
  AUTH_PAGE_LackSku_NOSECRET: 1135,
  AUTH_FUNC_LackSku_EXPORT: 1129,

  // 库存对账
  AUTH_PAGE_InventoryReconciliation: 1197,
  AUTH_FUNC_InventoryReconciliation_DETAIL: 1164,

  // 库存版本明细
  AUTH_PAGE_InventoryVersionDetail: 1198,
  AUTH_FUNC_InventoryVersionDetail_STATEMENT: 1200,
  AUTH_FUNC_InventoryVersionDetail_EXPORT: 1201,

  //公告管理
  AUTH_MENU_AnnounceManage: 2347,
  AUTH_PAGE_AnnounceManage: 2348,
  AUTH_FUNC_AnnounceManageUpdate: 2349,
  AUTH_FUNC_AnnounceManageView: 2350,

  // 拆单规则
  AUTH_PAGE_SplitOrderRule_NOSECRET: 2369,
  AUTH_FUNC_SplitOrderRule_EDIT: 2370,

  // 拆单管理
  AUTH_PAGE_OrderSplit: 1195,
  // 合单管理
  AUTH_PAGE_OrderMerge_NOSECRET: 2238,
  AUTH_FUNC_OrderMerge_EXPORT: 27781,

  // 库存推送规则
  AUTH_PAGE_InvBatchRule_NOSECRET: 2486,
  AUTH_FUNC_InvBatchRule_DETAIL: 2485,
  AUTH_FUNC_InvBatchRule_EDIT: 2484,
  AUTH_FUNC_InvBatchRule_CREATE: 2483,
  // 接口模板配置
  AUTH_PAGE_InterfaceModuleConfig_NOSECRET: 9631,
  AUTH_FUNC_InterfaceModuleConfig_EDIT: 9628,
  AUTH_FUNC_InterfaceModuleConfig_VIEW: 9630,
  AUTH_FUNC_InterfaceModuleConfig_CREATE: 9627,
  AUTH_FUNC_InterfaceModuleConfig_EFFECT: 9629,

  // 异常报文监控
  AUTH_PAGE_ExceptMessage_NOSECRET: 9690,
  AUTH_FUNC_ExceptMessage_VIEW: 9691,
  AUTH_FUNC_ExceptMessage_EDIT: 9692,
  AUTH_FUNC_ExceptMessage_PUSH: 9693,
  AUTH_FUNC_ExceptMessage_LOG: 9694,

  // 商品审核
  AUTH_PAGE_SkuAudit_NOSECRET: 12759,
  AUTH_FUNC_SkuAudit_AUDIT: 12760,

  // WMS地址配置
  AUTH_PAGE_WmsAddressConfig_NOSECRET: 999,

  // WMS对接配置
  AUTH_PAGE_WmsDockingConfig_NOSECRET: 999,

  // 库存中心盘点审核
  AUTH_PAGE_InventoryAudit_NOSECRET: 1201,
  AUTH_FUNC_InventoryAudit_EXPORT: 12761,
  AUTH_FUNC_InventoryAudit_UPLOAD: 1202,
  AUTH_FUNC_InventoryAudit_CANCELUPLOAD: 1203,

  // 规则中心：拦截调用规则
  AUTH_PAGE_InterceptRule_NOSECRET: 999,
  AUTH_FUNC_InterceptRule_VIEW: 23842,
  AUTH_FUNC_InterceptRule_EDIT: 23841,
  AUTH_FUNC_InterceptRule_CREATE: 23840,

  // 导入模板配置
  AUTH_PAGE_ImportModuleConfig_NOSECRET: 999,
  AUTH_FUNC_ImportModuleConfig_VIEW: 999,
  AUTH_FUNC_ImportModuleConfig_EDIT: 999,

  // 预付款规则
  AUTH_PAGE_PrePayRule_NOSECRET: 23296,
  AUTH_FUNC_PrePayRule_DETAIL: 23297,
  AUTH_FUNC_PrePayRule_EDIT: 23298,
  AUTH_FUNC_PrePayRule_CREATE: 23299,

  // 国际进口件
  AUTH_PAGE_ImportItem_NOSECRET: 136311,
  AUTH_FUNC_ImportItem_EXPORT: 136312,
  AUTH_FUNC_ImportItem_LOG: 136313,

  // 集运单
  AUTH_PAGE_CollectOrderPage_NOSECRET: 999,
  AUTH_FUNC_CollectOrderPage_DETAIL: 999,

  // 月台预约单查询
  AUTH_PAGE_PlatformManage: 27243,
  AUTH_FUNC_PlatformManage_APPROVAL: 27244,
  AUTH_FUNC_PlatformManage_SIGNIN: 27245,
  AUTH_FUNC_PlatformManage_LOADINGBEGIN: 27246,
  AUTH_FUNC_PlatformManage_FINISH: 27247,
  AUTH_FUNC_PlatformManage_CANCEL: 27248,
  AUTH_FUNC_PlatformManage_EDIT: 27253,
  AUTH_FUNC_PlatformManage_EXPORT: 999,

  // 集运管理
  // 关务品名维护
  AUTH_PAGE_CustomsNameMaintenance_NOSECRET: 999,
  AUTH_FUNC_CustomsNameMaintenance_EDIT: 999,

  // 自提网点信息维护
  // AUTH_PAGE_SelfServiceStation_NOSECRET: 24909,
  // AUTH_FUNC_SelfServiceStation_CREATE: 24910,
  // AUTH_FUNC_SelfServiceStation_EDIT: 24911,
  // AUTH_FUNC_SelfServiceStation_IMPORT: 24912,
  // AUTH_FUNC_SelfServiceStation_EXPORT: 24913,
  AUTH_PAGE_SelfServiceStation_NOSECRET: 999,
  AUTH_FUNC_SelfServiceStation_CREATE: 999,
  AUTH_FUNC_SelfServiceStation_EDIT: 999,
  AUTH_FUNC_SelfServiceStation_IMPORT: 999,
  AUTH_FUNC_SelfServiceStation_EXPORT: 999,

  // 集运管理目录
  AUTH_PAGE_CollectTransCenter_NOSECRET: 999,
  // 无主件识别处理
  AUTH_PAGE_UnknownManage_NOSECRET: 999,
  AUTH_FUNC_UnknownManage_IMPORT: 999,
  AUTH_FUNC_UnknownManage_EXPORT: 999,

  AUTH_FUNC_WmsAddressConfig_ENCRYPT: 999,

  // 通用的日志权限码
  AUTH_FUNC_LOG: 2377,

  // 郊区件
  AUTH_PAGE_SuburbanPartsManagement_NOSECRET: 999,
  AUTH_FUNC_SuburbanPartsManagement_EXPORT: 999,
  AUTH_FUNC_SuburbanPartsManagement_CREATE: 999,
  AUTH_FUNC_SuburbanPartsManagement_EDIT: 999,
  AUTH_FUNC_SuburbanPartsManagement_DELETE: 999,

  // 调拨关联明细录入
  AUTH_PAGE_AllocationDetail_NOSECRET: 999,
  AUTH_FUNC_AllocationDetail_IMPORT: 999,
  AUTH_FUNC_AllocationDetail_EDIT: 999,
  AUTH_FUNC_AllocationDetail_DELETE: 999,


  // 订单对应出库时间
  AUTH_PAGE_OrderCorrespondDeliveryTime_NOSECRET: 999,
  AUTH_FUNC_OrderCorrespondDeliveryTime_IMPORT: 999,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EDIT: 999,
  AUTH_FUNC_OrderCorrespondDeliveryTime_CREATE: 999,
  AUTH_FUNC_OrderCorrespondDeliveryTime_DELETE: 999,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EXPORT: 999,

  // 特殊时效发送配置
  AUTH_PAGE_SpecialEffectiveSendConf_NOSECRET: 999,
  AUTH_FUNC_SpecialEffectiveSendConf_IMPORT: 375060,
  AUTH_FUNC_SpecialEffectiveSendConf_EDIT: 999,
  AUTH_FUNC_SpecialEffectiveSendConf_CREATE: 999,
  AUTH_FUNC_SpecialEffectiveSendConf_DELETE: 999,
  AUTH_FUNC_SpecialEffectiveSendConf_EXPORT: 999,

  // 仓库绑定月台
  AUTH_FUNC_BindPlatform_LIST: 999,
  AUTH_FUNC_BindPlatform_CREATE: 999,
  AUTH_FUNC_BindPlatform_EDIT: 999,
  AUTH_FUNC_BindPlatform_DETAIL: 999,

  // 仓库绑定月台预约时间
  AUTH_FUNC_PlatformPeriod_LIST: 999,
  AUTH_FUNC_PlatformPeriod_CREATE: 999,
  AUTH_FUNC_PlatformPeriod_EDIT: 999,
  AUTH_FUNC_PlatformPeriod_DETAIL: 999,
  AUTH_FUNC_PlatformPeriod_DEL: 999,

  // 进销存管理
  AUTH_PAGE_PurchaseSaleManage_NOSECRET: 999,
  AUTH_FUNC_PurchaseSaleManage_EXPORT: 999,

  // PO采购订单管理
  AUTH_PAGE_PurchaseManagement: 999,
  AUTH_PAGE_PurchaseManagementDetail: 999,
  AUTH_FUNC_PurchaseManagement_EXPORT: 999,
};

export default authMap;
