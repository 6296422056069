import LonganSdk from 'longan-sdk';
import * as React from 'react';
import { CacheRoute, CacheSwitch } from 'react-router-cache-route';

import ErrorBoundary from 'components/ErrorBoundary';
import LoadingSkeleton from 'components/LoadingSkeleton';
import NotFoundPage from 'pages/NotFoundPage';

import { IMenuDataItem, IMenuItem } from './types';

interface IProps {
  prefix?: string;
  menuData: IMenuDataItem[];
  sfucode?: string;
}

/**
 * 对Menu数据进行了加工，将叶子节点及其对应的路由进行了自动生成，用以减少新增模块对路由进行的修改
 * @param arr 原始Menu数据，来自configs/dev.router.conf.tsx
 * @param keyPath 前导路径
 * @param prefix 路由前缀
 * @returns 用于ReactRoute的路由对象数组
 */
export function traversMenu(arr: IMenuDataItem[], keyPath: string[] = [], prefix: string | null = null) {
  let leafsArray: IMenuItem[] = [];

  arr.forEach(item => {
    const newKeyPath = keyPath.concat([item.path]);
    if (item.children) {
      if (item.exist) {
        leafsArray.push({
          path: item.route || (prefix ? `/${prefix}/${newKeyPath.join('/')}` : `/${newKeyPath.join('/')}`),
          componentName: item.componentName || `${item.path.substring(0, 1).toUpperCase()}${item.path.substring(1)}`,
          isDetail: !!item.isDetail,
        });
      }
      leafsArray = leafsArray.concat(traversMenu(item.children, [...newKeyPath], prefix));
    } else {
      leafsArray.push({
        path: item.route || (prefix ? `/${prefix}/${newKeyPath.join('/')}` : `/${newKeyPath.join('/')}`),
        componentName: item.componentName || `${item.path.substring(0, 1).toUpperCase()}${item.path.substring(1)}`,
        isDetail: !!item.isDetail,
      });
    }
  });
  return leafsArray;
}

/**
 * 页面组件异步加载
 * @param item 路由对象
 * @returns 页面组件
 */
export function getLazyComponent(item: IMenuItem) {
  const Comp = React.lazy(() =>
    import(`../../pages/${item.componentName}/index.tsx`).catch(err => {
      if (process.env.NODE_ENV === 'development') {
        console.log(err);
      } else {
        LonganSdk.dispatchError({
          error_message: err.toString(),
          error_content: `src/pages/${item.componentName} chunk load error`,
          error_level: '2',
          error_tag: 'chunkLoadError',
        });
      }

      // 如果路径不存在，则降级为兜底页；
      return import('../../pages/BackupPage/index').catch(err => console.log(err));
    }),
  );
  return (props: any) => (
    <React.Suspense fallback={<LoadingSkeleton />}>
      <Comp {...props} />
    </React.Suspense>
  );
}

/**
 * 项目路由组件
 */
export default class CoreRoute extends React.PureComponent<IProps, TDictionary<unknown>> {
  public makePathToComponent(menu: IMenuDataItem[]): IMenuItem[] {
    return traversMenu(menu, [], this.props.prefix);
  }

  public render() {
    const { menuData, sfucode } = this.props;
    if (!sfucode) {
      return <LoadingSkeleton />;
    }

    const pathToComponentArr = this.makePathToComponent(menuData);

    return (
      <ErrorBoundary>
        <CacheSwitch>
          <CacheRoute
            exact
            path="/"
            key="default"
            className="main-content-wrap"
            component={getLazyComponent({
              componentName: 'HomePage',
              isDetail: false,
              path: '/homePage',
            })}
          />
          {pathToComponentArr.map((item: IMenuItem) => (
            <CacheRoute
              exact
              cacheKey={() => (item.isDetail ? window.location.pathname + window.location.search : item.path)}
              multiple={item.isDetail}
              key={item.path}
              className="main-content-wrap"
              path={item.isDetail ? `${item.path}/:id` : item.path}
              component={getLazyComponent(item)}
            />
          ))}
          <CacheRoute path="*" component={NotFoundPage} />
        </CacheSwitch>
      </ErrorBoundary>
    );
  }
}
