/*
 * @Author: your name
 * @Date: 2021-02-19 14:03:39
 * @LastEditTime: 2021-03-24 17:59:38
 * @LastEditors: 董方旭
 * @Description: In User Settings Edit
 * @FilePath: /janus/src/pages/Layout/index.tsx
 */
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { WaterMark } from '@ant-design/pro-layout';
import { Layout as AntdLayout } from 'antd';
import CoreRoute from 'components/Router';
import menuConfig from 'configs/dev.router.conf';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/globalSlice';
import { selectCurrentLanguage, selectLayout, selectLoginUserFunctionAuth, selectLoginUserInfo } from 'store/selectors';
import { menuMessages } from 'utils/messages';
import { getParentUrl, getQueryString } from 'utils/utils';
import Menu from './components/Menu';
import { LayoutWrapper } from './components/Wrappers';
import { getMenuLangData } from './menuHelper';
import HeaderBar from './modules/HeaderBar';

const { Content, Sider } = AntdLayout;
const { useEffect, useState } = React;

const isOnFrame = window.frames.length !== window.parent.frames.length;
// 下面的isInFrame是用来测试的，实际没用到
const isInFrame = window.top !== window.self;
console.log('🚀 ~ isInFrame判断:', isInFrame);
const isOnline = process.env.PROJECT_ENV === 'online';

function Layout() {
  const [menuData, setMenuData] = useState([]);
  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);
  const lang = useSelector(selectCurrentLanguage);
  const authList = useSelector(selectLoginUserFunctionAuth);
  // const menuData = getMenuLangData(lang, menuConfig, menuMessages, authList);

  useEffect(() => {
    // 做一下dsc兼容处理，不展示首页
    if (isOnFrame && menuConfig[0] && menuConfig[0]?.path === 'homePage') {
      menuConfig.shift();
    }
    const data = getMenuLangData(lang, menuConfig, menuMessages, authList);
    setMenuData(data);
  }, [JSON.stringify(menuConfig), JSON.stringify(authList)]);
  const userInfo = useSelector(selectLoginUserInfo);

  let parentUrl = getParentUrl() || ('' as string);
  console.log('父级域名', parentUrl);
  if (typeof parentUrl !== 'string') {
    parentUrl = '';
  }
  const isInWpm =
    parentUrl.includes('wpm-fusion-web.sit.sf-express.com') ||
    parentUrl.includes('elog-wpm-nweb.sit.sf-express.com') ||
    parentUrl.includes('scc-wpm.sf-express.com');

  // 全局数据初始化
  useEffect(() => {
    // 无论是内嵌dsc还是wpm，都需要对cookie做处理
    if (isOnFrame || isInWpm) {
      const cert = getQueryString('cert');
      const castoken = getQueryString('castoken');
      console.log('cert and castoekn', cert, castoken);
      cert &&
        (document.cookie = `cert=${cert || ''};domain=${
          isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
        };path=/;secure;SameSite=None;`);
      castoken &&
        (document.cookie = `castoken=${castoken || ''};domain=${
          isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
        };path=/;secure;SameSite=None;`);
      dispatch(actions.updateLayoutCollapsed(true));
    } else {
      // 默认
      document.cookie = `cert=passport;domain=${
        isOnline ? '.sf-express.com' : '.sftcwl.com.cn'
      };path=/;secure;SameSite=None;`;
    }
    dispatch(actions.initGlobalData());
  }, []);

  const handleCollapse = (collapse: boolean) => {
    dispatch(actions.updateLayoutCollapsed(collapse));
  };

  return (
    <LayoutWrapper>
      <WaterMark
        content={userInfo.sfucode}
        fontSize={12}
        zIndex={99999}
        width={100}
        height={40}
        gapX={100}
        gapY={100}
        fontColor="rgba(0,0,0,0.05)"
      >
        {/* 嵌入wpm需要将左侧菜单给隐藏掉 */}
        {isInWpm ? null : (
          <HeaderBar collapsed={layout.collapsed}>
            {React.createElement(layout.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => handleCollapse(!layout.collapsed),
            })}
          </HeaderBar>
        )}
        <AntdLayout>
          {/* 嵌入wpm需要将左侧菜单给隐藏掉 */}
          {isInWpm ? null : (
            <Sider collapsible width={180} collapsedWidth={50} collapsed={layout.collapsed} trigger={null}>
              <Menu />
            </Sider>
          )}
          <Content style={{ flex: 1 }}>
            <div style={{ height: '100%' }}>
              <CoreRoute menuData={menuData} sfucode={userInfo.sfucode} />
            </div>
          </Content>
        </AntdLayout>
      </WaterMark>
    </LayoutWrapper>
  );
}

export default hot(Layout);
