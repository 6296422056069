/** LanguageProvider reducer */
import { i18nConf } from 'configs/base.conf';
import produce from 'immer';
import { getCookie } from 'utils/utils';

import { DEFAULT_LOCALE } from '../../i18n';
import { CHANGE_LOCALE } from './constants';

export interface IStore {
  locale: string;
}

export const initialState: IStore = {
  locale: getCookie(i18nConf.cookieName) || DEFAULT_LOCALE,
};

// eslint-disable-next-line default-param-last
const languageProviderReducer = (state = initialState, action: IRedux.TAction) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE:
        // eslint-disable-next-line no-param-reassign
        draft.locale = action.payload;
        break;
      default:
        break;
    }
  });

export default languageProviderReducer;
