import React from 'react';
import { Link, useHistory, type LinkProps } from 'react-router-dom';

interface ICustomLinkProps extends LinkProps {
  beforeClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomLink = ({ beforeClick, to, children, ...rest }: ICustomLinkProps) => {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (beforeClick) {
      event.preventDefault(); // 阻止默认行为
      beforeClick(event); // 执行自定义逻辑
      history.push(to as string); // 导航到新路径
    }
  };

  return (
    <Link to={to} onClick={handleClick} {...rest}>
      {children}
    </Link>
  );
};

/**
 * 包装了一下当前的Link组件，增加了一些自定义的逻辑，后续有需要可以再加
 * @description 1、添加了beforeClick属性，主要用于切换路由之前进行一些前置操作，比如改变页面标题（神策搜集数据需要）
 */
export default CustomLink;
