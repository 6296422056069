import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Tooltip } from 'antd';
import { headerBarTips } from 'utils/messages';

import enMessagePng from 'static/images/ic_en_message@2x.png';

interface IProps extends InjectedIntlProps {
  value: string;
  onChange: (value: string) => any;
}

function ToggleLanguage(props: IProps) {
  const { intl, value, onChange } = props;

  return (
    <div onChange={() => onChange(value === 'zh-CN' ? 'en-US' : value)}>
      <Tooltip title={intl.formatMessage(headerBarTips.lang_change)}>
        <img
          src={enMessagePng}
          width={20}
          height={20}
          alt={value}
        />
      </Tooltip>
    </div>
  );
}

export default injectIntl(ToggleLanguage);
