/*
 * @Author: 董方旭
 * @Date: 2021-02-08 14:04:14
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-06-03 19:26:13
 * @Description: 重写intl.formatMessage方法，解决key不存在时报错
 */
import * as React from 'react';
import { intlShape } from 'react-intl';

class RewriteIntl extends React.Component {
  public static childContextTypes = {
    intl: intlShape.isRequired,
  };

  public static contextTypes = {
    intl: intlShape,
  };

  public handleFormatMessage = (...args: any[]) => {
    if (args[0] && args[0].id && args[0].defaultMessage) {
      return this.context.intl.formatMessage(...args);
    }
    // if (process.env.NODE_ENV === 'development') {
    //   // eslint-disable-next-line no-console
    //   console.error('含有未匹配到的国际化字段, 请展开错误提示的堆栈信息进行检查');
    // }
    return '';
  };

  public getChildContext() {
    return {
      intl: {
        ...this.context.intl,
        formatMessage: this.handleFormatMessage,
      },
    };
  }

  public render() {
    return this.props.children;
  }
}

export default RewriteIntl;
