/*
 * @Author: 李淳
 * @Date: 2020-06-24 15:37:52
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-08-16 20:20:55
 * @Description: 面向fetch请求切面，对请求成功失败的附加处理；
 */
import { Modal, notification } from 'antd';
import { gotoPass } from 'configs/pass.conf';
import isEmpty from 'lodash/isEmpty';
import longan from 'longan-sdk';
import * as React from 'react';
import { fork, put, take } from 'redux-saga/effects';
import { commonActions } from 'utils/connect';
import {
  FETCH_FULFILLED_SUFFIX,
  FETCH_REJECTED_SUFFIX,
  POST_ACTION_PREFIX,
  USER_NOT_LOGIN_ERRNO,
  WITH_SUCCESS_CALLBACK,
} from 'utils/constants';
import { actions } from './globalSlice';

const isOnFrame = window.frames.length !== window.parent.frames.length;

function* fetchSuccessSaga(action: IRedux.TAction): Generator {
  const result = action?.payload;

  if (result && result.errno === 0) {
    // 【成功】
    if (action.type.indexOf(POST_ACTION_PREFIX) > -1) {
      // 预设只有post接口在正确时显示信息
      notification.success({
        message: '操作成功',
      });
    }
    // action带了自动刷新的标记，则dispath一个refresh的操作
    if (action.type.indexOf(WITH_SUCCESS_CALLBACK) > -1) {
      yield put(commonActions.refresh());
    }
  } else {
    notification.error({
      message: '网络错误，请稍候再试',
    });
    longan.dispatch({
      event_name: 'network_offline',
      event_desc: '网络错误',
      event_type: 'selfDefine',
    });
    yield put(actions.operateError(new Error(JSON.stringify(action))));
  }
}

function* fetchFailSaga(action: IRedux.TAction): Generator {
  const result = action?.payload;
  if (result && result.errno) {
    if (result.errno === USER_NOT_LOGIN_ERRNO) {
      // 未登录，跳转登录
      if (isOnFrame) {
        Modal.destroyAll();
        Modal.error({
          title: '用户信息异常，请在主应用中确认是否已登陆？',
        });
        return;
      }
      gotoPass('login');
      return;
    }
    const errMsg = React.createElement('div', null, result.errmsg ? result.errmsg : '操作失败，请稍候再试');
    const logId =
      result.lid || result.logId
        ? React.createElement('div', null, `当前操作的 lid：【${result.lid || result.logId}】`)
        : null;
    notification.error({
      // 不同于请求成功，所有的请求都会暴露失败消息
      message: React.createElement('div', null, [errMsg, logId]),
    });
    yield put(actions.operateError(new Error(JSON.stringify(action))));
    return;
  }
  notification.error({
    message: '网络错误，请稍候再试',
  });
  longan.dispatch({
    event_name: 'network_offline',
    event_desc: '网络错误',
    event_type: 'selfDefine',
  });
  yield put(actions.operateError(new Error(JSON.stringify(action))));
}

// The watcher: watch actions and coordinate worker tasks
function* watchFetchResult() {
  while (true) {
    const reg = new RegExp(`${FETCH_FULFILLED_SUFFIX}|${FETCH_REJECTED_SUFFIX}`);
    // eslint-disable-next-line no-loop-func
    const action: IRedux.TAction = yield take((data: IRedux.TAction) => !isEmpty(data.type.match(reg)));
    if (action.type.indexOf(FETCH_REJECTED_SUFFIX) > -1) {
      yield fork(fetchFailSaga, action);
    } else {
      yield fork(fetchSuccessSaga, action);
    }
  }
}

export default watchFetchResult;
