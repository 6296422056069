/*
 * @Author: 李淳
 * @Date: 2020-06-21 12:23:57
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-25 19:50:46
 * @Description: 项目基础配置
 */

const baseTableConf = {
  pageSize: 50,
  showTotal: total => `总计 ${total} 条`,
  pageSizeOptions: ['25', '50', '100'],
};

const i18nConf = {
  enable: true,
  defaultLocale: 'zh-CN',
  locales: ['zh-CN', 'en-US'],
  cookieName: 'lang',
};

module.exports = {
  name: 'Janus',
  entrance: '/homePage',
  // iconfont项目，如若需要权限，联系张宁
  // https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=2886751
  aliIconFontSourceURL: 'http://at.alicdn.com/t/c/font_2886751_ayb3puxa134.js',
  iconFontScriptURL: '/static/js/iconfont.js',
  baseTableConf,
  i18nConf,
};
