import dayjs from 'dayjs';
import sensors from 'static/files/sensorsdata.es6.min.js';
import { DEFAULT_DATETIME_FORMAT_STRING } from './constants';

function initSensorsData() {
  sensors.init({
    // ...初始化参数
    server_url:
      process.env.PROJECT_ENV === 'online'
        ? 'https://ubs.sf-express.com/sa?project=fxb'
        : 'https://ubs.sf-express.com/sa?project=fxbgrey',
    is_track_single_page: true,
    use_client_time: true,
    send_type: 'beacon',
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'default',
    },
  });

  sensors.registerPage({
    system_code: 'SCC-OMS',
    project_env: process.env.PROJECT_ENV,
  });

  sensors.quick('autoTrack');
  // 页面浏览时长
  sensors.use('PageLeave');
  // 页面加载时长
  sensors.use('PageLoad');
}

/**
 * 接口错误请求上报
 * @param { string } url - 接口请求地址
 * @param { string | number } status - http状态码
 * @param { string } errMsg - 业务逻辑错误信息
 * @param { string } params - 接口参数
 */
export const sensorsTrackNetworkError = (url, status, errMsg, params) => {
  const data = {
    http_url: url,
    http_code: status,
    page_url: window.location.pathname,
    $title: window.document.title,
    error_message: errMsg,
    request_params: params,
    create_time: dayjs().format(DEFAULT_DATETIME_FORMAT_STRING),
  };

  sensors.track('NetworkError', data);
};

/**
 * 页面js报错上报
 * @param {string} message 错误信息
 * @param {string} name 错误名称
 * @param {string} stack 错误堆栈信息
 * @param {string} scriptUrl 错误发生的脚本地址
 * @param {number} lineNo 错误行数
 * @param {number} columnNo 错误列数
 */
export const sensorsTrackJavaScriptError = (message, name, stack, scriptUrl, lineNo, columnNo) => {
  try {
    let page_error_stack;
    if (stack) {
      page_error_stack = stack.length <= 2048 ? stack : stack.slice(0, 2048);
    }
    const data = {
      error_name: name,
      error_message: message,
      error_stack: page_error_stack,
      page_url: window.location.pathname,
      error_script_url: scriptUrl,
      error_line: lineNo,
      error_column: columnNo,
      create_time: dayjs().format(DEFAULT_DATETIME_FORMAT_STRING),
    };
    sensors.track('JavaScriptError', data);
  } catch (error) {
    console.error('JavaScriptError upload failed', error);
  }
};

export default initSensorsData;
