import React from 'react';
import styled from 'styled-components';

const NotFoundPageContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .not_found_bg {
    font-size: 60px;
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .not_found_tip {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
`;

export default function NotFoundPage() {
  return (
    <NotFoundPageContainer>
      <div className="not_found_bg">404</div>
      <div className="not_found_tip">该页面不存在</div>
      <div className="not_found_tip">如没有页面权限, 请联系管理员</div>
    </NotFoundPageContainer>
  );
}
